/* Repair Process Section */
.repair-process {
  padding: 3rem 1rem;
  background: linear-gradient(135deg, #5476ae 0%, #f3f4f6 50%, #b37070 100%); /* Subtle gradient */
  text-align: center;
}

.repair-process h2 {
  font-size: 2.5rem;
  color: #222;
  margin-bottom: 1rem;
  font-weight: bold;
  position: relative;
}

.repair-process h2::after {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  background: #ff0000;
  margin: 0.5rem auto 1rem;
}

.repair-subtext {
  color: #555;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

/* Adding a centered background box for the steps */
.steps {
  background: #fff; /* White box for the steps */
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.step {
  background: #fff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.step-icon {
  font-size: 3rem;
  color: #ff0000;
  margin-bottom: 1rem;
}

.step span {
  display: inline-block;
  background: #ff0000;
  color: #fff;
  font-size: 1.5rem;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  margin: 1rem auto;
  font-weight: bold;
}

.step h3 {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 1rem;
}

.step p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

body[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

body[dir="ltr"] {
  direction: ltr;
  text-align: left;
}
