/* Footer Section */
.footer {
  background: linear-gradient(145deg, #1e1e1e, #2a2a2a);
  color: #fff;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-top: 4px solid #ff0000;
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.4);
  animation: fadeIn 1s ease-in-out;
}

.footer .container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  gap: 2rem;
}

/* Footer Left */
.footer-left {
  flex: 1;
  min-width: 300px;
}

.footer-logo {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ff0000;
  margin-bottom: 1rem;
}

.footer-left p {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.footer-left a {
  color: #ff0000;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.2s ease;
}

.footer-left a:hover {
  color: #d00000;
  transform: scale(1.05);
}

/* Icons */
.icon {
  margin-right: 0.5rem;
  color: #ff0000;
}

/* Map Container */
.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.map {
  width: 100%;
  max-width: 600px;
  height: 200px;
  border: 2px solid #fff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.map:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

/* Social Icons */
.footer-socials {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.social-icon {
  color: #fff;
  font-size: 1.8rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icon:hover {
  color: #ff0000;
  transform: rotate(15deg) scale(1.3);
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer .container {
    flex-direction: column;
    align-items: center;
  }

  .map {
    width: 100%;
  }
}
