/* Hero Section Styles */
.hero-section {
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 0.8s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: 'Arial', sans-serif;
}

.hero-overlay {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
}

.hero-content {
  z-index: 2;
  max-width: 800px;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  animation: fadeIn 1.5s ease-in-out;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
  font-weight: bold;
}

.hero-content p {
  font-size: 1.3rem;
  margin-bottom: 2rem;
  animation: fadeIn 2s ease-in-out;
  line-height: 1.8;
  color: #f5f5f5;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.hero-content .read-more {
  background: #ff0000;
  color: #fff;
  border: none;
  padding: 0.8rem 1.8rem;
  font-size: 1.1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  animation: fadeIn 2.5s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  font-weight: bold;
  letter-spacing: 0.05em;
}

.hero-content .read-more:hover {
  background: #d00000;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.5);
  transform: translateY(-3px);
}

.hero-controls {
  position: absolute;
  bottom: 30px;
  display: flex;
  gap: 12px;
  z-index: 2;
}

.hero-controls .control-dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.7);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.hero-controls .control-dot.active {
  background: #ff0000;
  border-color: #ff0000;
  transform: scale(1.2);
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
