/* Who We Are Section */
.who-we-are-section {
    padding: 4rem 1rem;
    background: linear-gradient(135deg, #e9f4fa 0%, #ffffff 100%);
    text-align: center;
  }
  
  .who-we-are-section .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Content Area */
  .who-we-are-section .content {
    flex: 1;
    max-width: 600px;
    text-align: left;
  }
  
  .who-we-are-section h2 {
    font-size: 2.5rem;
    color: #222;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  .who-we-are-section p {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.8;
    margin-bottom: 1.5rem;
  }
  
  /* Team Photo */
  .who-we-are-section .team-photo {
    flex: 1;
    max-width: 500px;
  }
  
  .who-we-are-section .team-photo img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .who-we-are-section .team-photo img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  /* Team Photo Slider */
.team-photo-slider {
  flex: 1;
  max-width: 500px;
  margin-top: 2rem;
}

.team-photo-slider .team-photo img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-photo-slider .team-photo img:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Slick Dots Styling */
.slick-dots li button:before {
  color: #ff0000; /* Dots color */
}

.slick-dots li.slick-active button:before {
  color: #d00000; /* Active dot color */
}

  
  /* Responsive Design */
  @media (max-width: 768px) {
    .who-we-are-section .container {
      flex-direction: column;
      text-align: center;
    }
  
    .who-we-are-section .content {
      text-align: center;
    }
  
    .who-we-are-section h2 {
      font-size: 2rem;
    }
  
    .who-we-are-section p {
      font-size: 1.1rem;
    }
  }
  