/* 
Callback Section - Neon Chaos Edition (More Responsive)
*/

/* KEYFRAMES */
@keyframes swirl {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes glitch {
  0%   { text-shadow: 0 0 5px #0ef; }
  20%  { text-shadow: 2px 0px 5px #f0f, -2px -2px 10px #0ff; }
  40%  { text-shadow: -2px 2px 5px #ff0, 2px -2px 10px #f0f; }
  60%  { text-shadow: -1px 1px 5px #0ff, -1px -1px 10px #ff0; }
  80%  { text-shadow: 1px -1px 5px #f0f, 1px 1px 10px #0ff; }
  100% { text-shadow: 0 0 5px #0ef; }
}

/* BASE STYLES */
.callback-section {
  position: relative;
  padding: 4rem 1rem;
  background: #000;
  color: #fff;
  overflow: hidden;
  font-family: "Arial", sans-serif;
}

.callback-section::before,
.callback-section::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: conic-gradient(from 0deg, #0ff, #f0f, #ff0, #0ff);
  animation: swirl 10s linear infinite;
  opacity: 0.1;
}

.callback-section::after {
  animation-direction: reverse;
}

.callback-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  padding: 0 1rem;
}

/* LEFT-SIDE IMAGE */
.callback-image {
  flex: 1 1 400px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.callback-image img {
  width: 100%;
  max-width: 400px;
  border: 3px solid #0ef;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 20px #0ff, 0 0 10px #f0f;
}

.callback-image img:hover {
  transform: scale(1.2) rotate(5deg);
  box-shadow: 0 0 30px #f0f, 0 0 20px #0ff;
}

/* RIGHT-SIDE CONTENT */
.callback-content {
  flex: 1 1 300px;
  max-width: 500px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.callback-content h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #0ef;
  text-transform: uppercase;
  text-shadow: 0 0 5px #0ef, 0 0 10px #0ef, 0 0 20px #f0f;
  animation: glitch 2s infinite;
}

.callback-description {
  font-size: 1.2rem;
  color: #ccc;
  margin-bottom: 2rem;
  text-shadow: 0 0 5px #fff;
}

/* CALLBACK FORM */
.callback-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  position: relative;
}

/* Group input & textarea together */
.callback-form input,
.callback-form textarea {
  width: 100%;
  max-width: 400px;
  padding: 1rem;
  border: 2px solid #0ef;
  border-radius: 5px;
  font-size: 1rem;
  background: #111;
  color: #fff;
  box-shadow: 0 0 10px #0ff, 0 0 5px #f0f;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Same placeholder color for both */
.callback-form input::placeholder,
.callback-form textarea::placeholder {
  color: #ccc;
}

/* Same focus effect for both */
.callback-form input:focus,
.callback-form textarea:focus {
  outline: none;
  border-color: #f0f;
  transform: scale(1.05);
  box-shadow: 0 0 20px #f0f, 0 0 10px #0ff;
}

/* SUBMIT BUTTON */
.callback-form button {
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  color: #000;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 15px #f0f, 0 0 10px #0ff;
  font-weight: bold;
  text-transform: uppercase;
}

.callback-form button:hover {
  transform: translateY(-3px) rotate(5deg) scale(1.1);
  box-shadow: 0 0 30px #f0f, 0 0 20px #0ff;
}

/* RESPONSIVE ADJUSTMENTS */

/* Medium Screens */
@media (max-width: 992px) {
  .callback-content h2 {
    font-size: 2.5rem;
  }

  .callback-description {
    font-size: 1.1rem;
  }

  /* Apply to both input & textarea */
  .callback-form input,
  .callback-form textarea {
    max-width: 300px;
  }

  .callback-form button {
    font-size: 1.1rem;
    padding: 0.9rem 1.8rem;
  }
}

/* Small Screens */
@media (max-width: 768px) {
  .callback-content h2 {
    font-size: 2rem;
  }

  .callback-description {
    font-size: 1rem;
  }

  /* Apply to both input & textarea */
  .callback-form input,
  .callback-form textarea {
    max-width: 100%;
  }

  .callback-form button {
    font-size: 1rem;
  }

  /* On smaller screens, reduce hover transformations */
  .callback-image img:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px #f0f, 0 0 10px #0ff;
  }
  
  .callback-form button:hover {
    transform: translateY(-2px) scale(1.05);
    box-shadow: 0 0 20px #f0f, 0 0 10px #0ff;
  }
}

/* Extra Small Screens */
@media (max-width: 480px) {
  .callback-content h2 {
    font-size: 1.8rem;
  }

  .callback-description {
    font-size: 0.9rem;
  }

  .callback-form button {
    font-size: 0.9rem;
    padding: 0.8rem 1.5rem;
  }
}
