/* Statistics Section */
.statistics-section {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #243b55, #141e30); /* Modern blue gradient background */
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Container */
.statistics-section .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  gap: 3rem;
}

/* Image Area */
.image-area {
  flex: 1.5; /* Larger image area */
  max-width: 700px;
  text-align: center;
}

.image-area img {
  width: 100%;
  height: auto;
  border-radius: 12px; /* Rounded edges */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4); /* Deep shadow for focus */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-area img:hover {
  transform: scale(1.05); /* Subtle hover zoom */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.6); /* Enhanced shadow on hover */
}

/* Content Area */
.content-area {
  flex: 1;
  max-width: 600px;
  text-align: left;
}

.content-area h2 {
  font-size: 3rem;
  font-weight: bold;
  color: #ff8c00; /* Vibrant orange */
  margin-bottom: 1.5rem;
}

.content-area p {
  font-size: 1.2rem;
  color: #d4d4d4; /* Soft gray for readability */
  line-height: 1.8;
  margin-bottom: 2.5rem;
}

/* Stats Grid */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two-column grid */
  gap: 2rem;
}

.stat-item {
  text-align: center;
  background: #1e293b; /* Darker background for contrast */
  padding: 2rem 1.5rem;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-8px); /* Lift effect */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
}

.stat-icon {
  font-size: 3rem;
  color: #ff8c00; /* Matching orange for icons */
  margin-bottom: 1rem;
}

.stat-item h4 {
  font-size: 2.5rem;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.stat-item p {
  font-size: 1.1rem;
  color: #d4d4d4; /* Subtle gray for text */
}

/* Responsive Design */
@media (max-width: 1024px) {
  .statistics-section .container {
    flex-direction: column;
    text-align: center;
  }

  .image-area {
    max-width: 100%;
  }

  .content-area {
    text-align: center;
    margin-bottom: 2rem;
  }

  .stats-grid {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}

@media (max-width: 768px) {
  .content-area h2 {
    font-size: 2.5rem;
  }

  .content-area p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .stat-item h4 {
    font-size: 2rem;
  }

  .stat-item p {
    font-size: 0.9rem;
  }
}
