/* FAQ Section */
.faq-section {
  padding: 4rem 1rem;
  background: linear-gradient(135deg, #e9f4fa 0%, #ffffff 100%); /* Soft gradient background */
  text-align: center;
}

.faq-section h2 {
  font-size: 2.5rem;
  color: #222;
  margin-bottom: 1rem;
  font-weight: bold;
  position: relative;
}

.faq-section h2::after {
  content: "";
  display: block;
  width: 80px;
  height: 3px;
  background: #ff0000;
  margin: 0.5rem auto 2rem;
}

.faq-intro {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 2rem;
}

/* FAQ List */
.faq-list {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.faq-item {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  transition: transform 0.3s, box-shadow 0.3s;
}

.faq-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* FAQ Question */
.faq-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.faq-icon {
  font-size: 1.5rem;
  color: #ff0000;
  margin-right: 1rem;
}

.faq-chevron {
  font-size: 1.2rem;
  color: #555;
  transition: transform 0.3s;
}

.faq-question:hover .faq-chevron {
  transform: rotate(180deg);
}

/* FAQ Answer */
.faq-answer {
  font-size: 1rem;
  color: #555;
  margin-top: 1rem;
  line-height: 1.6;
  display: none; /* Hidden by default */
}

/* Animation */
.faq-item:hover .faq-answer {
  display: block;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
