/* 
Proficiency Section - Enhanced Size

Changes made:
- Increased overall font sizes for better visibility.
- Larger brand logo (150px).
- More spacing and larger text for a more impactful display.
*/

/* ------------------------------ */
/* BASE SECTION STYLES */
/* ------------------------------ */

.proficiency-section {
  position: relative;
  padding: 6rem 1rem; /* Increased padding for more space */
  background: #fff; /* White background */
  color: #333;
  text-align: center;
  font-family: "Arial", sans-serif;
}

/* Container could be added if needed, currently unused:
.proficiency-container {
  max-width: 1200px;
  margin: 0 auto;
} */

/* ------------------------------ */
/* SECTION TITLE & DESCRIPTION */
/* ------------------------------ */

.proficiency-section h2 {
  font-size: 3rem; /* Increased from 2.5rem to 3rem */
  color: #333;
  margin-bottom: 1.5rem; /* Slightly more space below heading */
  font-weight: bold;
  text-transform: uppercase;
}

.proficiency-section p {
  color: #555;
  margin-bottom: 4rem; /* More space before the grid */
  font-size: 1.3rem; /* Increased from 1.1rem to 1.3rem for better readability */
}

/* ------------------------------ */
/* BRANDS GRID */
/* ------------------------------ */

.brands-grid {
  display: grid;
  /* Increased minimum width and spacing to give a more spacious layout */
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2.5rem; 
  justify-items: center;
}

/* ------------------------------ */
/* BRAND CARD */
/* ------------------------------ */

.brand-card {
  background: #f9f9f9;
  padding: 2.5rem;
  border-radius: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

/* ------------------------------ */
/* BRAND ICON & LOGO */
/* ------------------------------ */

.brand-icon {
  font-size: 2.4rem; /* Slightly larger icon */
  color: #666;
  margin-bottom: 1.5rem;
}

/* Increased logo size for a bigger picture */
.brand-logo {
  max-width: 150px; /* Increased from 100px to 150px */
  margin: 1.5rem auto;
  border: 1px solid #ccc;
  border-radius: 50%;
}

/* ------------------------------ */
/* BRAND TEXT */
/* ------------------------------ */

.brand-card h3 {
  font-size: 1.6rem; /* Increased from 1.4rem */
  color: #333;
  margin-bottom: 1rem;
}

.brand-founded {
  font-size: 1rem; /* Slightly larger */
  color: #777;
  margin-bottom: 1rem;
  font-style: italic;
}

.brand-group {
  font-size: 1.2rem; /* Increased from 1rem */
  color: #555;
  margin: 0;
  font-weight: bold;
}

.brand-group span {
  color: #333;
  font-size: 1.3rem; /* Slightly larger text */
  text-transform: uppercase;
}

/* ------------------------------ */
/* RESPONSIVE ADJUSTMENTS */
/* ------------------------------ */
@media (max-width: 768px) {
  .proficiency-section h2 {
    font-size: 2.5rem;
  }

  .proficiency-section p {
    font-size: 1.1rem;
    margin-bottom: 3rem;
  }

  .brand-card {
    padding: 2rem;
  }

  .brand-logo {
    max-width: 120px;
  }

  .brand-card h3 {
    font-size: 1.4rem;
  }

  .brand-founded {
    font-size: 0.9rem;
  }

  .brand-group {
    font-size: 1rem;
  }

  .brand-group span {
    font-size: 1.1rem;
  }
}

/* Further adjustments for very small screens if needed */
@media (max-width: 480px) {
  .proficiency-section h2 {
    font-size: 2rem;
  }

  .proficiency-section p {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .brand-logo {
    max-width: 100px;
  }
}
