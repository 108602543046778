/* Testimonials Section */
.testimonials-section {
  padding: 4rem 1rem;
  background: linear-gradient(135deg, #e0f7fa 0%, #ffffff 100%);
  text-align: center;
}

.testimonials-section h2 {
  font-size: 2.5rem;
  color: #222;
  margin-bottom: 2rem;
  font-weight: bold;
  position: relative;
}

.testimonials-section h2::after {
  content: "";
  display: block;
  width: 80px;
  height: 3px;
  background: #ff0000;
  margin: 0.5rem auto 1.5rem;
}

/* Testimonial Grid */
.testimonial-grid {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

/* Testimonial Card */
.testimonial-card {
  background: #fff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  perspective: 1000px;
}

.testimonial-card:hover {
  transform: rotateY(10deg) rotateX(10deg);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3);
}

/* Testimonial Image */
.testimonial-card img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.testimonial-card:hover img {
  transform: scale(1.1);
}

/* Testimonial Name */
.testimonial-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

/* Testimonial Location */
.testimonial-card .location {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1rem;
}

/* Testimonial Review */
.testimonial-card .review {
  font-size: 1.2rem;
  color: #777;
  font-style: italic;
  margin-bottom: 1rem;
  line-height: 1.5;
}

/* Testimonial Rating */
.testimonial-card .rating {
  font-size: 1.2rem;
  color: #ffcc00;
}
