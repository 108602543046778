/* Services Section */
.services-section {
  background: #f9f9f9;
  padding: 3rem 1rem;
  text-align: center;
}

.services-section h2 {
  font-size: 2.5rem;
  color: #222;
  margin-bottom: 2rem;
  font-weight: bold;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.service-card {
  background: #fff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 280px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.service-icon {
  font-size: 3rem;
  color: #ff0000;
  margin-bottom: 1rem;
}

.service-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.service-card p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.service-button {
  background: #ff0000;
  color: #fff;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  font-weight: bold;
}

.service-button:hover {
  background: #d00000;
  transform: scale(1.05);
}

/* Modal Styles */
.service-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  position: relative;
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 1.5rem;
}

.modal-content h3 {
  font-size: 2rem;
  color: #222;
  margin-bottom: 1rem;
}

.modal-content p {
  font-size: 1.2rem;
  color: #555;
}

/* Close Button */
.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 2rem;
  color: #ff0000;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.modal-close:hover {
  transform: scale(1.1); /* Slight hover effect */
}


html[dir="rtl"] .services-list {
  direction: rtl;
}

html[dir="rtl"] .service-card {
  text-align: right;
}
