.header {
  background-color: #222;
  color: #fff;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky; /* Keeps the header fixed at the top */
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
}


.header .container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 5rem; /* Add padding for better spacing */
  box-sizing: border-box; /* Include padding in the width calculation */
  padding-left: -10px;
}

/* Logo Styles */
.header .logo {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap:0.5rem;
  color: #bb1616;
  object-fit: contain;
  vertical-align: middle; /* Ensure alignment with other elements */
  font-family: Georgia, 'Times New Roman', Times, serif;
  width: 200px;
 
  
}

#testlogo{
  width: 180px;  
  height: auto;  /* Maintain aspect ratio */
  object-fit: contain;
  

}





.header .logo-icon {
  font-size: 2rem;
  color: #ff0000; /* Makes the icon stand out */
  
}

/* Navigation Styles */
.header .nav {
  display: flex;
  gap: 2rem;
  transition: transform 0.3s ease-in-out;
}

.header .nav a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  transition: color 0.3s, transform 0.2s;
  padding-left: 10px; /* Adds spacing for all screen sizes */
}

.header .nav a:hover {
  color: #ff0000;
  transform: translateY(-2px);
}

/* Quote Button Styles */
.header .quote-button {
  background-color: #ff0000;
  color: #fff;
  padding: 0.6rem 1.2rem;
  text-transform: uppercase;
  border-radius: 20px;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* Adds subtle depth */
}

.header .quote-button:hover {
  background-color: #d00000;
  transform: translateY(-3px);
}

/* Mobile Menu Toggle Button */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.8rem;
  color: #fff;
  cursor: pointer;
  z-index: 1100;
}

.language-switcher {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: auto;
  position: relative; /* Ensure it is positioned relative to its parent */
  z-index: 1000; /* Bring it above other elements if necessary */
}

.language-switcher img {
  width: 30px;
  height: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.language-switcher img:hover {
  transform: scale(1.1);
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
}

.language-switcher img.active {
  border: 2px solid #ff0000; /* Highlight active language */
}


/* Base styles remain unchanged */

/* Responsive Design for Medium Devices (Tablets) */
@media (max-width: 992px) {
  .header .container {
    padding: 0 2rem; /* Reduce padding for medium screens */
  }

  .header .logo {
    font-size: 1.1rem; /* Smaller font size for medium screens */
    width: auto; /* Adjust width automatically */
  }

  .header .nav {
    gap: 1rem; /* Smaller gap between navigation links */
  }

  .header .nav a {
    font-size: 0.9rem; /* Smaller font size for medium screens */
  }

  .header .quote-button {
    padding: 0.5rem 1rem; /* Smaller padding for medium screens */
    font-size: 0.8rem; /* Smaller font size for medium screens */
  }

  .language-switcher img {
    width: 25px; /* Smaller flags for medium screens */
    height: 17px; /* Adjusted height for flags */
  }
}


/* Responsive Design for Small Devices (Mobile) */
@media (max-width: 768px) {
  .header {
    padding: 0.5rem 1rem; /* Keep padding moderate for small screens */
  }

  .header .container {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center-align items */
    padding: 0; /* Remove additional padding inside container */
  }

  .header .logo {
    margin-bottom: 0.5rem; /* Space below the logo */
  }

  .header .nav {
    position: absolute;
    top: 100%; /* Align directly below the header */
    left: 0;
    right: 0;
    background: #222; /* Maintain theme consistency */
    flex-direction: column;
    align-items: center; /* Center-align links */
    width: 100%; /* Full width navigation */
    display: none; /* Hide by default, show when menu toggles */
    transform: translateY(-100%); /* Start above view */
    transition: transform 0.3s ease; /* Smooth transition for dropdown */
  }

  .header .nav.nav-open {
    display: flex; /* Show navigation when menu is open */
    transform: translateY(0%); /* Slide down smoothly */
  }

  .header .nav a {
    width: 100%; /* Full width links */
    text-align: center; /* Center text for each link */
    padding: 0.8rem 0; /* Increased padding for easier touch */
    border-bottom: 1px solid #444; /* Add separator for clarity */
  }

  .header .quote-button {
    width: 90%; /* Slightly less than full width */
    margin-top: 0.5rem; /* Space above the quote button */
    margin-bottom: 0.5rem; /* Space below the quote button */
  }

  .menu-toggle {
    display: block; /* Ensure toggle button is always visible on small screens */
    position: absolute;
    right: 1rem; /* Position toggle on the top right */
    top: 0.5rem; /* Align with the header padding */
  }

  .language-switcher {
    display: flex; /* Show language switcher when menu is open */
    flex-wrap: wrap;
    justify-content: center; /* Center the flags within the switcher */
    margin-top: 1rem; /* Space from the last navigation link */
    width: 100%; /* Full width to align with other menu items */
  }

  .language-switcher img {
    width: 25px; /* Smaller flags for mobile screens */
    height: 17px; /* Adjusted height for flags */
    margin: 2px; /* Slight margin for spacing between flags */
  }
}

/* Additional Styling for Very Small Screens */
@media (max-width: 480px) {
  .header {
    padding: 0.4rem 0.8rem; /* Slightly reduce padding */
  }

  .header .logo {
    font-size: 1rem; /* Adjust font size for very small screens */
  }

  .header .nav a {
    font-size: 0.8rem; /* Reduce font size for navigation links */
    padding: 0.7rem 0; /* Adjust padding for compact appearance */
  }

  .header .quote-button {
    font-size: 0.7rem; /* Reduce font size for the quote button */
    padding: 0.5rem 1rem; /* Adjust padding to fit text */
  }
}
