/* Products Section */
.products-section {
  padding: 4rem 1rem;
  background: linear-gradient(135deg, #1e1e1e 0%, #333 100%);
  text-align: center;
  font-family: "Arial", sans-serif;
  position: relative;
  overflow: hidden;
}

.products-section::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,0,255,0.3) 0%, transparent 50%), 
              radial-gradient(circle, rgba(0,255,255,0.3) 0%, transparent 50%);
  background-repeat: no-repeat;
  background-size: 50% 50%;
  background-position: left top, right bottom;
  animation: spin 20s linear infinite;
  z-index: 0;
}

@keyframes spin {
  0% { transform: rotate(0deg);}
  100% { transform: rotate(360deg);}
}

.products-section h2 {
  font-size: 3rem;
  color: #0ef;
  margin-bottom: 1rem;
  font-weight: bold;
  text-shadow: 0 0 10px #0ef, 0 0 20px #0ef;
  z-index: 1;
  position: relative;
}

.products-section p {
  color: #aaa;
  margin-bottom: 2.5rem;
  font-size: 1.1rem;
  z-index: 1;
  position: relative;
}

/* Product Grid */
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  justify-items: center;
  z-index: 1;
  position: relative;
}

/* Product Card */
.product-card {
  background: linear-gradient(135deg, #222 0%, #111 100%);
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.3), 0 0 5px rgba(255, 0, 255, 0.3);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
  max-width: 300px;
  border: 2px solid #0ef;
  position: relative;
  overflow: hidden;
}

.product-card::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: conic-gradient(from 0deg, #0ff, #f0f, #0ff);
  animation: card-spin 3s linear infinite;
  opacity: 0.1;
}

@keyframes card-spin {
  0% { transform: rotate(0deg);}
  100% { transform: rotate(360deg);}
}

.product-card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 0 25px rgba(255, 0, 255, 0.5), 0 0 15px rgba(0, 255, 255, 0.5);
}

/* Product Image */
.product-card img {
  max-width: 150px;
  border-radius: 50%;
  margin-bottom: 1rem;
  transition: transform 0.3s ease-in-out;
  border: 3px solid #0ef;
}

.product-card:hover img {
  transform: rotate(360deg);
}

/* Product Title */
.product-card h3 {
  color: #0ff;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  text-shadow: 0 0 5px #0ff, 0 0 10px #0ff;
}

/* Product Description */
.product-description {
  font-size: 1rem;
  color: #ccc;
  margin-bottom: 1rem;
  line-height: 1.5;
}

/* Product Price */
.product-price {
  color: #ff0;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 0 0 5px #ff0;
}

/* Actions */
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

/* Like Button */
.like-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #aaa;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

.like-button:hover {
  color: #ff0;
  transform: scale(1.2);
}

.like-button .liked {
  color: #f0f;
  text-shadow: 0 0 5px #f0f;
}

/* Like Count */
.like-count {
  font-size: 0.9rem;
  color: #aaa;
}

/* Buy Button */
.buy-button {
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  color: #000;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 0 10px #f0f, 0 0 20px #0ff;
}

.buy-button:hover {
  transform: translateY(-3px) rotate(5deg);
  box-shadow: 0 0 20px #f0f, 0 0 30px #0ff;
}

