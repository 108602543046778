body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global RTL Styling */
html[dir="rtl"] {
  direction: rtl;
}

html[dir="rtl"] .header {
  text-align: right;
}

html[dir="rtl"] .nav {
  justify-content: flex-end;
}

html[dir="rtl"] .language-switcher {
  margin-left: 0;
  margin-right: auto;
}

html[dir="rtl"] .nav a {
  text-align: right;
}

/* Global LTR Styling */
html[dir="ltr"] {
  direction: ltr;
}

/* Font Styles for Arabic and Kurdish */
html[lang="ar"],
html[lang="ku"] {
  font-family: "Amiri", serif; /* A great font for Arabic and Kurdish */
}

/* Font Styles for Latin Languages */
html[lang="en"],
html[lang="fr"],
html[lang="tr"],
html[lang="es"],
html[lang="sq"] {
  font-family: "Roboto", sans-serif; /* Modern font for Latin-based languages */
}

